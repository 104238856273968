import React, { useState } from "react"
import "./privacy-policy-popup.css"
import "./cookies-popup.css"
import slugHandler from "../api/slugHandlerBrowser"
import { navigate } from 'gatsby-link'
import {Grid, Button, Box} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import red from "@material-ui/core/colors/red"

const WhiteButton = withStyles((theme) => ({
  root: {
    color: "#8A1002",
    backgroundColor: red[50],
    '&:hover': {
      backgroundColor: red[100],
    },
  },
}))(Button);

const CookiesPopup= (props)=>{

    let [cookiesPopUpVisible, setCookiesPopUpVisible] = useState(true)

    const inBrowser = typeof window !== `undefined`;
      const cookiesPopupClosed = () => {
        return inBrowser ? localStorage.getItem("cookies-popup-closed") : true;
      }
      
      const acceptCookies = () => {
        setCookiesPopUpVisible(false);
        if (inBrowser) localStorage.setItem("cookies-popup-closed", true);
      }

      const acceptBasicCookies = () => {
        setCookiesPopUpVisible(false);
        if (inBrowser) localStorage.setItem("cookies-popup-closed", true);
        window.gaOptout();
      }
    
      const rejectCookies = () => {
        setCookiesPopUpVisible(false);
        if (inBrowser) localStorage.setItem("cookies-popup-closed", true);
        navigate(slugHandler(props.language, "/cookies"));
      }

    return(
        cookiesPopUpVisible && !cookiesPopupClosed() &&
         <Box className="bot-popup-cookies">
            <Grid container spacing={0} className="bot-popup-cookies_in">
              <Grid item xs={12}><Box className="popup-cookies-text" color="text.primary">{props.translation.popupCookies.message}</Box></Grid>
              <Grid container spacing={3} className="bot-btns">
                <Grid item xs={4}><WhiteButton variant="contained" onClick={rejectCookies}>
                  {props.translation.popupCookies.settings}</WhiteButton></Grid>
                <Grid item xs={4}><WhiteButton variant="contained" onClick={acceptBasicCookies}>
                  {props.translation.popupCookies.acceptBasic}</WhiteButton></Grid>
                <Grid item xs={4}><Button variant="contained" color="primary" onClick={acceptCookies}>
                  {props.translation.popupCookies.accept}</Button></Grid>
              </Grid>
            </Grid>
        </Box>
    )
}

export default CookiesPopup